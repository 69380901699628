/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
  }
  
  #nprogress .bar {
    background: #29d;
  
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 2px;
  }
  
  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    -webkit-box-shadow: 0 0 10px #29d, 0 0 5px #29d;
            box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;
  
    -webkit-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
  }
  
  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }
  
  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  
    border: solid 2px transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;
  
    -webkit-animation: nprogress-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
  }
  
  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }
  
  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
  
  @-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
svg:not(:root).svg-inline--fa {
  overflow: visible; }

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em; }
  .svg-inline--fa.fa-lg {
    vertical-align: -.225em; }
  .svg-inline--fa.fa-w-1 {
    width: 0.0625em; }
  .svg-inline--fa.fa-w-2 {
    width: 0.125em; }
  .svg-inline--fa.fa-w-3 {
    width: 0.1875em; }
  .svg-inline--fa.fa-w-4 {
    width: 0.25em; }
  .svg-inline--fa.fa-w-5 {
    width: 0.3125em; }
  .svg-inline--fa.fa-w-6 {
    width: 0.375em; }
  .svg-inline--fa.fa-w-7 {
    width: 0.4375em; }
  .svg-inline--fa.fa-w-8 {
    width: 0.5em; }
  .svg-inline--fa.fa-w-9 {
    width: 0.5625em; }
  .svg-inline--fa.fa-w-10 {
    width: 0.625em; }
  .svg-inline--fa.fa-w-11 {
    width: 0.6875em; }
  .svg-inline--fa.fa-w-12 {
    width: 0.75em; }
  .svg-inline--fa.fa-w-13 {
    width: 0.8125em; }
  .svg-inline--fa.fa-w-14 {
    width: 0.875em; }
  .svg-inline--fa.fa-w-15 {
    width: 0.9375em; }
  .svg-inline--fa.fa-w-16 {
    width: 1em; }
  .svg-inline--fa.fa-w-17 {
    width: 1.0625em; }
  .svg-inline--fa.fa-w-18 {
    width: 1.125em; }
  .svg-inline--fa.fa-w-19 {
    width: 1.1875em; }
  .svg-inline--fa.fa-w-20 {
    width: 1.25em; }
  .svg-inline--fa.fa-pull-left {
    margin-right: .3em;
    width: auto; }
  .svg-inline--fa.fa-pull-right {
    margin-left: .3em;
    width: auto; }
  .svg-inline--fa.fa-border {
    height: 1.5em; }
  .svg-inline--fa.fa-li {
    width: 2em; }
  .svg-inline--fa.fa-fw {
    width: 1.25em; }

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -.125em;
  width: 1em; }
  .fa-layers svg.svg-inline--fa {
    -webkit-transform-origin: center center;
            transform-origin: center center; }

.fa-layers-text, .fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center; }

.fa-layers-text {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
          transform-origin: center center; }

.fa-layers-counter {
  background-color: #ff253a;
  border-radius: 1em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #fff;
  height: 1.5em;
  line-height: 1;
  max-width: 5em;
  min-width: 1.5em;
  overflow: hidden;
  padding: .25em;
  right: 0;
  text-overflow: ellipsis;
  top: 0;
  -webkit-transform: scale(0.25);
          transform: scale(0.25);
  -webkit-transform-origin: top right;
          transform-origin: top right; }

.fa-layers-bottom-right {
  bottom: 0;
  right: 0;
  top: auto;
  -webkit-transform: scale(0.25);
          transform: scale(0.25);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right; }

.fa-layers-bottom-left {
  bottom: 0;
  left: 0;
  right: auto;
  top: auto;
  -webkit-transform: scale(0.25);
          transform: scale(0.25);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left; }

.fa-layers-top-right {
  right: 0;
  top: 0;
  -webkit-transform: scale(0.25);
          transform: scale(0.25);
  -webkit-transform-origin: top right;
          transform-origin: top right; }

.fa-layers-top-left {
  left: 0;
  right: auto;
  top: 0;
  -webkit-transform: scale(0.25);
          transform: scale(0.25);
  -webkit-transform-origin: top left;
          transform-origin: top left; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
          filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  position: relative;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em; }

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em; }

.fa-inverse {
  color: #fff; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #1890ff;
}
.clearfix::after {
  display: block;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #1890ff;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 0 #1890ff;
          box-shadow: 0 0 0 0 #1890ff;
  -webkit-box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
          box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 #1890ff;
            box-shadow: 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 #1890ff;
            box-shadow: 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-row {
  display: flex;
  flex-flow: row wrap;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-rtl {
  direction: rtl;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  flex: 0 1 auto;
  max-width: 100%;
  min-height: 1px;
}
.ant-col.ant-col-rtl {
  float: right;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
}

